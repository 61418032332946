<template lang="html">
  <div id="casos">
    <div class="vista">
      <div class="row w-full">
        <!--<div class="vx-col lg:w-1/5 sm:w-full">
          <vs-button color="primary"  icon-pack="feather" icon="icon-zap" to="/nuevoCaso">Nuevo Caso</vs-button>
          <br>
        </div>
        <div class="vx-col lg:w-1/5 sm:w-full">
          <vs-button color="primary"  icon-pack="feather" icon="icon-zap" to="/nuevaCotizacion">Cotización Rápida</vs-button>
          <br>
        </div>
        <div class="vx-col lg:w-1/5 sm:w-full">
          <vs-button @click="activePrompt=true;popupActive=false;" class="export">Exportar a tabla de Excel</vs-button>
          <vs-prompt title="Exportar a Excel" class="export-options" cancel-text="Cancelar" @cancle="clearFields" @accept="exportToExcel" accept-text="Exportar" @close="clearFields" :active.sync="activePrompt">
              <input v-model="fileName" placeholder="Nombre del archivo" class="w-full" />
              <v-select v-model="selectedFormat" :options="formats" class="my-4" />
              <div class="flex">
                <span class="mr-4">Tamaño de la celda automático:</span>
                <vs-switch v-model="cellAutoWidth">Tamaño automático</vs-switch>
              </div>
          </vs-prompt>
        </div>-->
      </div>
    </div>
    <br>

    <vs-table :data="casos" max-items="10" pagination filter noDataText="No hay datos para mostrar" class="" search>
      <template slot="header">
        <div class="row w-full" style="">
          <h4 class="lista">Consulta reportes de credito</h4>
        </div>
      </template>


      <template slot="header">

        <div class="row conDisponibles" style="color:#015694 !important; font-weight:600;">
          <div class="col-md-2">
          </div>
          <div class="col-md-5">
            Consultas restantes: {{this.consultasDisponibles}}

          </div>
          <div class="col-md-5" v-if="this.IsPermitted">
            <button type="button" class="btn btn-success" @click="openRegistro()" :disabled="validateForm">Solicitar
              Reporte</button>
          </div>
        </div>
      </template>
      <template slot="thead">
        <!--<vs-th sort-key="Folio">-->
        <vs-th>
          Folio de Consulta
        </vs-th>
        <vs-th>
          Folio de Caso Completo
        </vs-th>
        <vs-th>
          Fecha
        </vs-th>
        <vs-th>
          Solicitante
        </vs-th>
        <vs-th>
          Correo
        </vs-th>
        <vs-th>
          Usuario
        </vs-th>
        <vs-th>
          Organizacion
        </vs-th>
        <vs-th>
          Reporte
        </vs-th>

      </template>

      <template slot-scope="{data}">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">

          <vs-td :data="data[indextr].Id">
            {{data[indextr].Id}}
          </vs-td>

          <vs-td :data="data[indextr].Id">
            {{data[indextr].Precalificacion}}
          </vs-td>

          <vs-td :data="data[indextr].Id">
            {{data[indextr].Fecha}}
          </vs-td>

          <vs-td :data="data[indextr].Id">
            {{data[indextr].SolicitanteNombre}}
          </vs-td>

          <vs-td :data="data[indextr].Id">
            {{data[indextr].Solicitante}}
          </vs-td>

          <vs-td :data="data[indextr].Id">
            {{data[indextr].Broker}}
          </vs-td>
          <vs-td :data="data[indextr].Id">
            {{data[indextr].Organizacion}}

          </vs-td>

          <vs-td :data="data[indextr].Id" v-if="data[indextr].ConError == '0'" class="celda-enlace">
           <img :src="'https://cotizador.socasesores.com/app/img/IconoVerde.PNG'"  @click="AbrirReporte(data[indextr].Id)"  class="enlace-m"> 
          </vs-td>


          <vs-td :data="data[indextr].Id" v-if="data[indextr].ConError == '1'" class="celda-enlace">
            <vx-tooltip :text='data[indextr].ErrorString' position="top">
              <img :src="'https://cotizador.socasesores.com/app/img/IconoRojo.PNG'" class="enlace-m">
            </vx-tooltip>
          </vs-td>





        </vs-tr>
      </template>
    </vs-table>


    <!-- <div class="excel">
      <button @click="activePrompt=true;popupActive=false;"  class="btn btn-exportar mt-4" >
        <svg xmlns="http://www.w3.org/2000/svg" width="29.871" height="29.871" viewBox="0 0 29.871 29.871">
          <g id="vuesax_twotone_login" data-name="vuesax/twotone/login" transform="translate(-300 -444)">
            <g id="login" transform="translate(300 444)">
              <g id="Group_649" data-name="Group 649" transform="translate(4.979 11.824)">
                <path id="Vector" d="M0,6.372,3.186,3.186,0,0" transform="translate(9.558)" fill="none" stroke="#26558f" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                <path id="Vector-2" data-name="Vector" d="M0,0H12.658" transform="translate(0 3.186)" fill="none" stroke="#26558f" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
              </g>
              <path id="Vector-3" data-name="Vector" d="M0,0C5.5,0,9.957,3.734,9.957,9.957S5.5,19.914,0,19.914" transform="translate(14.936 4.979)" fill="none" stroke="#26558f" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" opacity="0.4"/>
              <path id="Vector-4" data-name="Vector" d="M0,0H29.871V29.871H0Z" transform="translate(29.871 29.871) rotate(180)" fill="none" opacity="0"/>
            </g>
          </g>
        </svg> <strong>Exportar a tabla de Excel</strong>
      </button>
      <vs-prompt title="Exportar a Excel" class="export-options" cancel-text="Cancelar" @cancle="clearFields" @accept="exportToExcel" accept-text="Exportar" @close="clearFields" :active.sync="activePrompt">
              <input type="text" v-model="fileName" placeholder="Nombre del archivo" class="vs-input" />
              <v-select v-model="selectedFormat" :options="formats" class="my-4" />
              <div class="flex">
                <span class="mr-4">Tamaño de la celda automático:</span>
                <vs-switch v-model="cellAutoWidth">Tamaño automático</vs-switch>
              </div>
      </vs-prompt>
    </div>-->
  </div>

</template>

<script>
import md5 from 'js-md5';
import vSelect from 'vue-select'
export default {
  props: {
     guid: {
       type: String,
       required: false
     },
   },
   components: {
     'v-select': vSelect,
   },
  data(){
		return{
      filtroCasos:'1',
      key:'',
      consultasDisponibles:0,
      // BuroCreditoStatus:'',
			casos:[],
      allcasos:[],
      allFilterCasos:[],
			estatus:[],
			destinos:[],
      selected:[],
      estatu:'',
      socData:null,
      socStatus:[],
      fileName: '',
      formats:['xlsx', 'csv', 'txt'],
      cellAutoWidth: true,
      selectedFormat: 'xlsx',
      headerTitle: ['Id', 'Ejecutivo', 'Fecha', 'SolicitanteNombre','Correo electrónico','Teléfono', 'Estatus','Destino','MontoSolicitado','Organizacion'],
      headerVal: ['Id', 'BrokerNombre', 'Fecha', 'SolicitanteNombre','Solicitante','SolicitanteTelefonoCelular', 'EstatusNombre','DestinoNombre','MontoSolicitado','Organizacion'],
      headerTitleSup: ['Id', 'Ejecutivo','Origen', 'Fecha', 'SolicitanteNombre','Correo electrónico','Teléfono', 'Estatus','Destino','MontoSolicitado','Organizacion'],
      headerValSup: ['Id', 'BrokerNombre','VersionOrigen', 'Fecha', 'SolicitanteNombre','Solicitante','SolicitanteTelefonoCelular', 'EstatusNombre','DestinoNombre','MontoSolicitado','Organizacion'],

      popupActive: false,
      activePrompt: false,

      IsPermitted: false,
      count: 0
		}
  },

  computed:{
    validateForm(){
      
      return this.consultasDisponibles == '0' ||  this.consultasDisponibles == '' 
    }
  },


	mounted(){
    if (this.guid) {
        const word = this.guid;
      
      if(word.includes("@")== false){
          this.verificaUsuario()
      }else{

        this.ObtenCredenciales()

      }



    }else {
      if(!JSON.parse(localStorage.getItem('userInfo'))){
        this.$router.push('/login').catch(() => {})
      }else {
        this.getApikey()
        this.getDestinos()
        //this.getSolicitudes()
       // this.getEstatus()
      }
    }
    if (this.$store.state.AppActiveUser.WidgetColorPrimario != '' && this.$store.state.AppActiveUser.WidgetColorPrimario) {
      this.$vs.theme({
        primary:this.$store.state.AppActiveUser.WidgetColorPrimario
      })
    }
    this.$vs.loading({
      container: '#casos',
      scale: 0.6
    })
	},
  watch: {
    filtroCasos: function() {
      
     
    },
    estatu: function() {
      if (this.estatu == null) {
        this.casos= this.allcasos
      }else {
        this.filter(this.estatu)
      }
    }
  },
	methods:{
    handleSelected(tr) {
      this.$router.push('/caso/'+tr).catch(() => {})
    },
    filter(id){
      var newArray = this.allFilterCasos.filter(function (el) {
        return el.Estatus == id;
      });
      this.casos=newArray
    },
    getApikey(){
      this.key=JSON.parse(localStorage.getItem('userInfo')).ApiKey;
    },
    AbrirReporte(IdConsulta){
      // var WhatsPrint =  window.open('https://cotizador.socasesores.com/admin/formatos/FormatoConsultaBuro.php?s='+IdConsulta)
      var WhatsPrint =  window.open('https://cotizador.socasesores.com/admin/formatos/FormatoConsultaCirculoCredito.php?s='+IdConsulta)
    },

		getDestinos(){
      let self=this
			var objRequestListaDestinos = {
				strApiKey: this.key,
				strMetodo: 'ListaDestinos',
			}
			this.$axios.post('/',objRequestListaDestinos,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
			.then(
				response => {
					if(response.data.intEstatus == 0){
						this.destinos=response.data.objContenido
            this.getSolicitudes()

            this.getTotalConsultas()
            // this.getConsultasDisponibles()
            // this.getCountDisponibles()
            this.GetEjecutivo()

					}else {
            this.$vs.notify({
              title:'Ocurrio un error en ListaDestinos',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
				}
			).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema1',
          text:error,
          color:'danger',
          position:'top-right'
        })
			})
		},
    openRegistro() {

      this.$router.push('/ReporteUser')

    },
    
    GetEjecutivo(){
      let self=this
			var objRequestCount = {
				strApiKey: this.key,
				strMetodo: 'MisDatos'
			}

      this.$axios.post('/',objRequestCount,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
			.then(
				response => {
					if(response.data.intEstatus == 0){
             let Ejecutivo= response.data.objContenido
            //  this.BuroCreditoStatus= Ejecutivo.BuroCredito
            //alert(this.BuroCreditoStatus)

					}else {
            this.$vs.notify({
              title:'Ocurrio un error en Mis datos',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
				}
			).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
			})

    },

    async getTotalConsultas () {

      try {

        // this.$vs.loading({
        //   container: '#casos',
        //   scale: 0.6,
        // })

        const consultasComparador = await this.getConsultasComparador()
        const { intEstatus, objContenido, strError } = consultasComparador 
        const consultasSISEC = await this.getConsultasSISEC()             
        const { Total, ReasonBlocked, IsPermitted } = consultasSISEC

         //return console.log({ intEstatus, Total, strError })

        if ( !intEstatus && Total === 0 ) {

          this.count = 0
          this.IsPermitted = false
          this.consultasDisponibles = 0

        /*  return this.$vs.notify({
            title: 'Ocurrio un error',
            text: 'El bróker no tiene la facultad de realizar consultas.',
            color:'danger',
            position:'top-right'
          })*/

        } 
        else{
          //this.count = Total + objContenido
          this.count = Total
          this.IsPermitted = true
          this.consultasDisponibles = this.count
        }
        

        this.$vs.loading.close('#casos > .con-vs-loading')
        
      } catch (error) {
        this.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      }

    },

    async getTokenSisec () {
      try {
        const objRequest = {
          username: 'user',
          password: 'user',
          grant_type: 'password'
        }

        const URL_API_SISEC = 'https://apicirculocredito.sisec.mx/token'
        // const URL_API_SISEC = 'https://apicirculocredito.sisec.mx/token'

        let formBody = [];
        for (const property in objRequest) {
          let encodedKey = encodeURIComponent(property);
          let encodedValue = encodeURIComponent(objRequest[property]);
          formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");

        const data = await this.$axios.post(URL_API_SISEC, formBody,{headers : {'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'}})

        return data

      } catch (error) {
        this.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })

        return false
      }
    },

    async getConsultasSISEC () {
      try {
        
        const TOKEN_SISEC = await this.getTokenSisec()
        const { access_token } = TOKEN_SISEC.data

        const userInfoJson = localStorage.getItem('userInfo')
        const { SocGuid } = JSON.parse(userInfoJson)
  
        if ( !SocGuid ) {

          return { Total: 0, ReasonBlocked: 'El bróker no tiene la facultad de realizar consultas.', IsPermitted: false }

        }

        const objRequest = {
          EmployeeSOCId: SocGuid,
          PersonType: 'Fisico'
        }

        const URL_API_SISEC = 'https://apicirculocredito.sisec.mx/api/CirculoCredito/Estado'
        // const URL_API_SISEC = 'https://apicirculocredito.sisec.mx/api/CirculoCredito/Estado' // PROD

        const resp = await this.$axios.post(URL_API_SISEC, objRequest, { 
          headers : {
            'Authorization': `Bearer ${access_token}`,
            'Content-Type': 'application/json; charset=UTF-8'
          }
        })

        const { status, data } = resp

        if ( status !== 200 ) {
          this.$vs.notify({
            title:'Ocurrio un error en Consultas disponibles',
            text:'Ocurrió un problema, inténtelo nuevamente más tarde.',
            color:'danger',
            position:'top-right'
          })

          return { Total: 0, ReasonBlocked: 'Ocurrió un problema, inténtelo nuevamente más tarde.', IsPermitted: false }
        }

        return data

      } catch (error) {
        this.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      }
    },

    async getConsultasComparador () {

      try {
        const objRequestCount = {
          strApiKey: this.key,
          strMetodo: 'BrokerConsultasBuro',
          objParametros: {
            ApiKey: this.key
          }
        }

        const { data } = await this.$axios.post('/',objRequestCount,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})        
        if ( data.intEstatus ) {
          return { intEstatus: data.intEstatus, objContenido: 0, strError: data.strError }
        }

        return { intEstatus: data.intEstatus, objContenido: data.objContenido, strError: null }

      } catch (error) {
        this.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      }

    },

		getSolicitudes(){
      let self=this
			var objRequestListaCasos = {
				strApiKey: this.key,
				strMetodo: 'ListaBurosEjecutivoApiKey2',
        objEjecutivo: {
           ApiKey: this.key
       }
			}
			this.$axios.post('/',objRequestListaCasos,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
			.then(
				response => {
					if(response.data.intEstatus == 0){
            this.casos= response.data.objContenido
            this.allcasos=this.casos
            this.allFilterCasos = this.casos.Id
            this.allFilterCasos = this.casos.Precalificacion
            this.allFilterCasos = this.casos.Solicitante
            this.allFilterCasos = this.casos.SolicitanteNombre
            this.allFilterCasos = this.casos.Broker
            this.allFilterCasos = this.casos.Organizacion
            this.allFilterCasos = this.casos.Fecha
            let textoError=''
            for(var i=0; i<this.allcasos.length;i++){
              if(this.allcasos[i].ConError ==true  &&  this.allcasos[i].ErrorString){
               
               
                //this.allcasos[i].ErrorString=JSON.parse(this.allcasos[i].ErrorString[0])
                for(var j=0; j<this.allcasos[i].ErrorString.length;j++){
                 
                  textoError =this.allcasos[i].ErrorString[0]
             
                }
               
                  this.allcasos[i].ErrorString=this.allcasos[i].ErrorString[0]
                  // console.log(this.allcasos)
              }
              textoError=''
            }
            // console.log(this.allcasos)
            this.$vs.loading.close('#casos > .con-vs-loading')
					}else {
            this.$vs.notify({
              title:'Ocurrio un error en ListaCasos',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
				}
			).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema4',
          text:error,
          color:'danger',
          position:'top-right'
        })
			})
		},


    exportToExcel () {
      if(this.filtroCasos=='2'){
        this.headerVal =this.headerValSup
        this.headerTitle = this.headerTitleSup
      }else{
        this.headerVal =this.headerVal
        this.headerTitle = this.headerTitle
      }


      import('@/vendor/Export2Excel').then(excel => {
        const list = this.allcasos
        const data = this.formatJson(this.headerVal, list)
        excel.export_json_to_excel({
          header: this.headerTitle,
          data,
          filename: this.fileName,
          autoWidth: this.cellAutoWidth,
          bookType: this.selectedFormat
        })
        this.clearFields()
         this.filename = ''
      })
    },
    formatJson (filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => {
        return v[j]
      }))
    },
    clearFields () {
      this.filename = ''
      this.cellAutoWidth = true
      this.selectedFormat = 'xlsx'
    },
	}
}
</script>
<style lang="css" scoped>
th{
  text-align: left;
  padding-top:90px !important;
  padding-right: 20px !important;
}

.vs-con-table .vs-con-tbody {

    background: #fff !important;
    }
.vs-input {
    padding: 0px;
    border-radius: 0 5px 5px 0 !important;}
.vs-input2 {
    padding: 0px;
    border-radius: 5px !important;
    border: none;
    margin-top: 10px;
    max-width: 190px !important;

    }
.vs-input3 {
    padding: 0px;
    border-radius:0 5px 5px 0 !important;
    border: none;
    margin-top: 10px;
    max-width: 180px !important;
    margin-left: -6px;

    }
    select{padding: 0.55rem !important;}
.alinear{
  margin-bottom: 10px;
}
.lupa {
    margin-top: 10px;
    border: none;
    padding: 6px;
    background: #fff;
    border-radius: 5px 0 0 5px;
        -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-box-shadow: 0 0 0 0 rgb(0 0 0 / 15%);
    box-shadow: 0 0 0 0 rgb(0 0 0 / 15%);
    margin-left: 255px;
}


 
 .vuesax-app-is-ltr .vs-table--search-input{
   /*padding-left: 1.5em !important;*/
 }

select#estatus.search-input{border: none !important;
}



.w-full{padding-top: 0 !important;}
.excel{
  float: right;
    margin-right: 20px;
      position: relative;
      top: -50px;
      font-weight: 600;
  }
.colorNuevo{
  background: #B7DCB8;
}
@media screen and (max-width:1180px){
  h4.lista {
                width: 200px;
            }
  .lupa {
    border-radius: 5px 0 0 5px;
    margin-left: 150px;
}
.vs-input2{
  margin-left: 150px;
}
}
@media screen and (max-width:1024px) {
  h4.lista{
    position: relative;
    top:10px;
    width: 100%;
  }
   .lupa {
    margin-left: 90px;
}
.vs-input2{
  margin-left: 100px;
}

  
}
@media screen and (max-width:915px) {
   .vs-input2,
  .lupa{
    margin-left: 0px;
    background-color: #fff !important;
  }
  .vs-input2,
  .vs-input3{
     max-width: 100% !important;
     width: 100%;
     background-color: #fff !important;
      }
      .alinear-input{
        margin-right: auto;
        margin-left: auto;
      }
  .excel{
    float: none;
    margin-right: auto;
    margin-left: auto;
    top:0;
    max-width: 300px;
  }
  .col-md-1{
    width: 100%;
  }
  h4.lista{
    position: relative;
    top:50px;
    width: 100%;
  }
  
}
@media screen and (max-width:896px) {
   .vs-input2,
  .lupa{
    margin-left: 0px;
    background-color: #fff !important;
  }
  .vs-input2,
  .vs-input3{
     max-width: 100% !important;
     width: 100%;
     background-color: #fff !important;
      }
      .alinear-input{
        margin-right: auto;
        margin-left: auto;
      }
  .excel{
    float: none;
    margin-right: auto;
    margin-left: auto;
    top:0;
    max-width: 300px;
  }
  .col-md-1{
    width: 100%;
  }
  h4.lista{
    position: relative;
    top:50px;
    width: 100%;
  }
  
}

@media screen and (max-width:768px) {
  .vs-input2,
  .lupa{
    margin-left: 0px;
    background-color: #fff !important;
  }
  .vs-input2,
  .vs-input3{
     max-width: 100% !important;
     width: 100%;
     background-color: #fff !important;
      }
      .alinear-input{
        margin-right: auto;
        margin-left: auto;
      }
  .excel{
    float: none;
    margin-right: auto;
    margin-left: auto;
    top:0;
    max-width: 300px;
  }
  h4.lista{
    width: 300px !important;
        top: 50px;
    position: relative;

  }}

@media screen and (max-width:667px){
    .vs-input2,
  .lupa{
    margin-left: 0px;
    background-color: #fff !important;
  }
  .vs-input2,
  .vs-input3{
     max-width: 100% !important;
     width: 100%;
     background-color: #fff !important;
      }
      .alinear-input{
        margin-right: auto;
        margin-left: auto;
      }
  
}
@media screen and (max-width:656px) {
 
  .vs-input2,
  .lupa{
    margin-left: 0px;
    background-color: #fff !important;
  }
  .vs-input2,
  .vs-input3{
     max-width: 100% !important;
     width: 100%;
     background-color: #fff !important;
      }
      .alinear-input{
        margin-right: auto;
        margin-left: auto;
      }
  .excel{
    float: none;
    margin-right: auto;
    margin-left: auto;
    top:0;
    max-width: 300px;
  }
}
</style>
